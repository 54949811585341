// Core Admin Variables

$enable-sidebar-nav-rounded: false;

$border-color: $gray;
$layout-transition-speed: 0.15s ease-in-out;
$text-color: #383a35;
$text-color-light: #676a6c;
$theme-color: $brand-primary;

// Social Colors
$facebook: #3b5998;
$twitter: #00aced;
$linkedin: #4875b4;
$google-plus: #d34836;
$flickr: #ff0084;
$tumblr: #32506d;
$xing: #026466;
$github: #4183c4;
$html5: #e34f26;
$openid: #f78c40;
$stack-overflow: #fe7a15;
$youtube: #b00;
$css3: #0170ba;
$dribbble: #ea4c89;
$google-plus: #bb4b39;
$instagram: #517fa4;
$pinterest: #cb2027;
$vk: #45668e;
$yahoo: #400191;
$behance: #1769ff;
$dropbox: #007ee5;
$reddit: #ff4500;
$spotify: #7ab800;
$vine: #00bf8f;
$foursquare: #1073af;
$vimeo: #aad450;

// Navbar

$navbar-height:                     60px;
$navbar-bg:                         #fff;
$navbar-border: (
    bottom: (
        size:                       1px,
        style:                      solid,
        color:                      #adadad
    )
);
$navbar-brand-width:                139px;
$navbar-brand-height:               37px;
$navbar-brand-bg:                   darken($gray-dark,10%);
$navbar-brand-logo:                 url('/assets/images/clients/nci/navbar-brand-logo.png');
$navbar-brand-logo-mobile:          url('/assets/images/clients/nci/navbar-brand-logo.png');
$navbar-brand-logo-size:            contain;
$navbar-brand-border: (
    bottom: (
        size:                       1px,
        style:                      solid,
        color:                      darken($gray-dark,10%)
    )
);
$navbar-brand-margin-left:          10px;

$navbar-color:                      $text-color;
$navbar-hover-color:                $text-color;
$navbar-active-color:               $text-color;
$navbar-disabled-color:             lighter($navbar-color, 20%);

// Task sidebar
$task-sidebar-width:                400px;

// Sidebar

$sidebar-width:                     220px;
$mobile-sidebar-width:              220px;
$sidebar-padding:                   0;
$sidebar-minimized-width:           50px;
$sidebar-minimized-height:          $sidebar-minimized-width;
$sidebar-compact-width:             50px;
$sidebar-compact-height:            $sidebar-compact-width;
$sidebar-color:                     #fff;
$sidebar-bg:                        #000;
$sidebar-header-bg:                 rgba(0,0,0,.2);
$sidebar-footer-bg:                 rgba(0,0,0,.2);
$sidebar-borders: (
    right: (
        size:                       1px,
        style:                      solid,
        color:                      $border-color
    )
);

// Sidebar Navigation
// side bar reports
$sidebar-nav-child-bg: 	            #32312C;
$sidebar-nav-item-child-bg:         lighten($sidebar-nav-child-bg,25%);
$sidebar-nav-item-child-hover-bg:   lighten($sidebar-nav-child-bg,15%);
$sidebar-nav-item-child-active-bg:  lighten($sidebar-nav-child-bg,5%);


$sidebar-nav-color:                 $brand-primary;
$sidebar-nav-title-padding-y:       .95rem;
$sidebar-nav-title-padding-x:       1rem;
$sidebar-nav-title-color:           $text-muted;
$sidebar-nav-link-padding-y:        .75rem;
$sidebar-nav-link-padding-x:        0.9rem;
$sidebar-nav-link-color:            #fff;
$sidebar-nav-link-bg:               transparent;
$sidebar-nav-link-icon-color:       #fff;
$sidebar-nav-item-color:            lighten($brand-primary, 10%);

$sidebar-nav-link-borders: (
    bottom: (
        size:                       0px,
        style:                      solid,
        color:                      $border-color
    )
);
$sidebar-nav-link-hover-color:      #fff;
$sidebar-nav-link-hover-bg:         darken($sidebar-bg, 5%);
$sidebar-nav-link-hover-icon-color: #fff !important;

$sidebar-nav-link-hover-borders:    0;
$sidebar-nav-link-active-color:   #fff;
$sidebar-nav-link-active-bg:        darken($sidebar-bg, 5%);
$sidebar-nav-link-active-icon-color:#fff;

$sidebar-nav-link-active-borders:   0;

$sidebar-nav-dropdown-color:        $gray-dark;
$sidebar-nav-dropdown-bg:           darken($sidebar-bg, 3%);
$sidebar-nav-dropdown-borders:      0;

// Secondary Nav
$secondary-nav-active-color:        #e4e4e4;
$secondary-nav-hover-color:         #efefef;

// Top Navigation
$top-nav-bg: #fff;
$top-nav-color:                     $body-color;
$top-nav-borders: (
  bottom: (
    size:                           1px,
    style:                          solid,
    color:                          $border-color
  )
);
$top-nav-ul-borders: (
  all: (
    size:                           1px,
    style:                          solid,
    color:                          $border-color
  )
);

$top-nav-hover-color:               #fff;
$top-nav-hover-bg:                  $brand-primary;
$top-nav-active-color:              #fff;
$top-nav-active-bg:                 $brand-primary;
$top-nav-height:                    $navbar-height - 15px;

// Breadcrumb

$breadcrumb-borders: (
    bottom: (
        size:                       1px,
        style:                      solid,
        color:                      $border-color
    )
);
$breadcrumb-padding-y: .5rem;
$breadcrumb-line-height: 2em;
$breadcrumb-height: 50px;

// Aside

$aside-menu-width:                  250px;
$aside-menu-color:                  $gray-dark;
$aside-menu-bg:                     #fff;
$aside-menu-borders: (
  left: (
    size:                           1px,
    style:                          solid,
    color:                          $border-color
  )
);

$aside-menu-nav-padding-y:          .75rem;
$aside-menu-nav-padding-x:          1rem;

//content
$content-divider-height:            19px;

// Footer

$footer-height:                     50px;
$footer-bg:                         $gray-lightest;
$footer-color:                      $body-color;
$footer-borders: (
  top: (
    size:                           1px,
    style:                          solid,
    color:                          $border-color
  )
);

// Cards

$card-icon-bg:                      transparent;
$card-icon-color:                   $body-color;


$login-logo: url('/assets/images/PoweredByShift.png');
$login-brand-logo: url('/assets/images/clients/nci/login_logo.png');
$login-brand-logo-width: 300px;
$login-brand-logo-height: 150px;

// Billboards
$billboard-red:                     #c51230;
$billboard-green:                   #18b52d;
$billboard-yellow:                  #f9bb11;
$billboard-blue:                    #065799;
$billboard-grey:                    #404041;

// Report Tables
$table-bg-highlight: #9e9e9e;

// Combined Bar Charts
$combined-bar-green: #18b52d;
$combined-bar-blue: #224197;
$combined-bar-yellow: #f9bb11;
$combined-bar-red: #c51230;
$datatable-trend-toggle-select-width: 110px;
